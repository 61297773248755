<template>
  <PageCard
    pageName="Actions"
    :iconClass="['fa', 'fa-location-arrow', 'fa-lg']"
  >
    <template slot="page_content">
      <v-wait for="fetchingActions">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row justify-content-center">
          <div class="col-sm-3 mb-1 search-box">
            <b-input-group>
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                placeholder="Search"
                ref="search"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-sm-7 text-right mb-1">
            <b-button
              size=""
              variant="primary"
              class="fw-btn mt-3 ml-3"
              v-b-modal.actionForm
              >Create New Action</b-button
            >
          </div>
          <div class="col-sm-10">
            <b-table
              :items="actions"
              :fields="tableFields"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              @filtered="onFiltered"
              empty-text="No records found."
              responsive="md"
              striped
              hover
              small
            >
              <template v-slot:cell(description)="data">
                <div>
                  {{ data.item.description || 'NA' }}
                </div>
              </template>
              <template v-slot:cell(editAction)="data">
                <a href="#" @click="showEditActionModal(data.item)">Edit</a>
              </template>
              <template v-slot:cell(deleteAction)="data">
                <a
                  href="#"
                  @click="setSelectedAction(data.item)"
                  v-b-modal.deleteConfirmation
                  >Delete</a
                >
              </template>
            </b-table>
            <b-row>
              <b-col md="6">
                <b-pagination
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>

        <b-modal
          id="deleteConfirmation"
          ref="deleteConfirmation"
          title="Delete Action"
          @ok="deleteAction"
          @cancel="resetSelectedAction"
        >
          <p>Are you sure, want to delete the action?</p>
        </b-modal>

        <ActionForm
          :selectedAction="selectedAction"
          @actionCreated="fetchActions"
          @revokeSelectedAction="clearSelectedAction"
        ></ActionForm>
      </v-wait>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import PageCard from '@/modules/core/components/layouts/PageCard'
import ActionForm from '../components/ActionForm'

export default {
  name: 'SuperAdminActions',
  components: {
    PageCard,
    ActionForm
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchActions()
  },
  data() {
    return {
      actions: [],
      selectedAction: null,
      filter: null,
      currentPage: 1,
      perPage: 20,
      totalRows: 1
    }
  },
  computed: {
    tableHeaders() {
      return {}
    },
    tableFields() {
      return [
        { key: 'name', label: 'Name', sortable: true, thClass: 'action-name' },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          thClass: 'action-desc'
        },
        { key: 'editAction', label: '', sortable: false },
        { key: 'deleteAction', label: '', sortable: false }
      ]
    }
  },
  methods: {
    setSelectedAction(action) {
      this.selectedAction = action
    },
    clearSelectedAction() {
      this.selectedAction = null
    },
    showEditActionModal(action) {
      this.selectedAction = action
      this.$nextTick(function() {
        this.$root.$emit('bv::show::modal', 'actionForm')
      })
    },
    resetSelectedAction() {
      this.selectedAction = null
    },
    fetchActions() {
      this.$wait.start('fetchingActions')
      this.resetSelectedAction()
      this.$http
        .get('/api/super_admin/actions', {
          handleErrors: true
        })
        .then(res => {
          this.actions = res.data.actions
          this.totalRows = this.actions.length
        })
        .then(() => {
          this.$wait.end('fetchingActions')
          this.$nextTick(function() {
            this.$refs.search.$el.focus()
          })
        })
    },
    deleteAction(evt) {
      evt.preventDefault()
      this.$http
        .delete(`/api/super_admin/actions/${this.selectedAction.id}`, {
          handleErrors: true
        })
        .then(
          res => {
            this.setSelectedAction(null)
            this.skipDeletedActions(res.data.action_id)
            this.$refs.deleteConfirmation.hide()
            Vue.toasted.show('Action deleted successfully', {
              icon: 'user-circle',
              type: 'success'
            })
          },
          () => {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        )
    },
    skipDeletedActions(actionId) {
      this.actions = this.actions.filter(action => action.id !== actionId)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style lang="scss">
.action-name {
  width: 30%;
}

.action-desc {
  width: 50%;
}

.search-box {
  align-self: center;
}
</style>

<template>
  <b-modal
    @show="initForm"
    @hide="clearSelectedAction"
    id="actionForm"
    ref="actionForm"
    size="md"
    :title="this.action.id ? 'Edit Action' : 'Create Action'"
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-form>
      <b-form-group
        label="Name"
        label-for="name"
        :label-cols="3"
        :horizontal="true"
      >
        <b-form-input
          id="name"
          type="text"
          placeholder="Action Name"
          v-model="action.name"
          :class="{
            'is-invalid': $v.action.name.$error,
            'is-valid': !$v.action.name.$invalid
          }"
          @blur.native="$v.action.name.$touch()"
          autocomplete="off"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.action.name.required"
          >Action Name can't be blank</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-if="!$v.action.name.minLen"
          >Action Name must be of minimum 3 characters
          length</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="description"
        :label-cols="3"
        :horizontal="true"
      >
        <b-form-textarea
          id="description"
          v-model="action.description"
          placeholder="Action description"
          rows="3"
          max-rows="10"
        >
        </b-form-textarea>
      </b-form-group>
    </b-form>
    <div slot="modal-footer">
      <div class="row text-right">
        <div class="col-sm-12">
          <b-button
            size=""
            variant="secondary"
            class="fw-btn mr-1"
            @click="hideModal"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="btn btn-primary fw-btn"
            @click="updateAction"
            :disabled="$v.$invalid"
            v-if="action.id"
            >Update</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="btn btn-primary fw-btn"
            @click="createAction"
            :disabled="$v.$invalid"
            v-else
            >Create</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ActionForm',
  props: ['selectedAction'],
  data() {
    return {
      action: { id: null, name: null, description: null }
    }
  },
  methods: {
    initForm() {
      this.action = this.selectedAction ? this.selectedAction : this.action
    },
    hideModal() {
      this.$v.$reset()
      this.$refs.actionForm.hide()
      this.clearSelectedAction()
    },
    clearSelectedAction() {
      this.action = { name: null, description: null }
      this.$emit('revokeSelectedAction')
    },
    createAction() {
      let data = new window.FormData()
      data.append('actions[name]', this.action.name)
      data.append('actions[description]', this.action.description)

      this.$http
        .post('/api/super_admin/actions', data, { handleErrors: true })
        .then(res => {
          if (res.data.status === 'success') {
            Vue.toasted.show('Action created successfully.', {
              icon: 'user-circle',
              type: 'success'
            })
            this.hideModal()
            this.$emit('actionCreated')
          } else {
            let message =
              res.data.message || 'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, { icon: 'chain-broken', type: 'error' })
          }
        })
    },
    updateAction() {
      let data = new window.FormData()
      data.append('actions[name]', this.action.name)
      data.append('actions[description]', this.action.description)

      this.$http
        .patch(`/api/super_admin/actions/${this.action.id}`, data, {
          handleErrors: true
        })
        .then(res => {
          if (res.data.status === 'success') {
            Vue.toasted.show('Action updated successfully.', {
              icon: 'user-circle',
              type: 'success'
            })
            this.hideModal()
            this.$emit('actionCreated')
          } else {
            let message =
              res.data.message || 'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, { icon: 'chain-broken', type: 'error' })
          }
        })
    }
  },
  validations: {
    action: {
      name: {
        required,
        minLen: minLength(3)
      }
    }
  }
}
</script>

<style lang="scss"></style>
